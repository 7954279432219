import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SyncUser, SyncClean } from '../components/user.jsx'
import { config } from '../components/config.jsx'

export function LoginLayout () {
  console.log(config.dusk_url + '/login?redirect_uri=' + config.dawn_url + '/sdaklfjsadflsajdfklsadfjsaldkfjsadlfjsdalfkjaldfksafweyhowancaoshdgsa')
  window.location.href = config.dusk_url + '/login?redirect_uri=' + config.dawn_url + '/sdaklfjsadflsajdfklsadfjsaldkfjsadlfjsdalfkjaldfksafweyhowancaoshdgsa'
}

export function LogoutLayout () {
  SyncClean()

  const path = config.dusk_url + '/logout'
  window.location.href = path
}

export function LoginAuthOk () {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/home', { replace: true })
  }, [navigate])
}

export const loginAuthOkLoader = async () => {
  await SyncUser()

  return null
}
