import axios from 'axios'
import { redirect } from 'react-router-dom'
import { config } from '../components/config.jsx'

const backend = axios.create({
  withCredentials: true,
  baseURL: config.dusk_url + '/sunshine'
})

const localStorageKeyUserInfo = 'user.info'
const localStorageKeyMessageError = 'msg.error'

function sessionStorageGet (key) {
  const d = window.sessionStorage.getItem(key)
  return d ? JSON.parse(d) : null
}

function sessionStorageStore (key, data) {
  window.sessionStorage.setItem(key, JSON.stringify(data))
}

function clearCacheAndSessionStorage () {
  window.localStorage.clear()
  window.sessionStorage.clear()
}

async function backendRequest (url) {
  let data = null
  await backend.get(url)
    .then(function (response) {
      if (response.status === 200) {
        data = response.data
      } else {
        clearCacheAndSessionStorage()
      }
    })
    .catch(function () {
      clearCacheAndSessionStorage()
      // console.log(error)
    })

  return data
}

async function userRequest (url) {
  let data = null
  if (IsAuthenticated()) {
    data = backendRequest(url)
  }

  if (!data) {
    redirect('/login')
  }

  return data
}

async function adminRequest (url) {
  let data = null
  if (IsAuthenticated() && IsAdmin()) {
    data = backendRequest(url)
  }

  if (!data) {
    redirect('/login')
  }

  return data
}

function getUserInfoData () {
  return sessionStorageGet(localStorageKeyUserInfo)
}

export function GetAndClearMessageError () {
  const msg = sessionStorageGet(localStorageKeyMessageError)
  sessionStorageStore(localStorageKeyMessageError, null)

  return msg
}

async function fetchAndStoreUserInfoData () {
  clearCacheAndSessionStorage()

  await backend.get('/info')
    .then(function (response) {
      if (response.status === 200) {
        sessionStorageStore(localStorageKeyUserInfo, response.data)
      } else {
        sessionStorageStore(localStorageKeyMessageError, 'message failed with: ' + response.message)
      }
    })
    .catch(function (error) {
      if (error.response) {
        sessionStorageStore(localStorageKeyMessageError, 'message failed with: ' + error.message)
      } else {
        sessionStorageStore(localStorageKeyMessageError, 'no response')
      }
    })
}

export async function SyncUser () {
  clearCacheAndSessionStorage()

  return await fetchAndStoreUserInfoData()
}

export async function SyncClean () {
  clearCacheAndSessionStorage()
}

export async function MeData () {
  return userRequest('/me')
}

export async function BalanceAccountData () {
  return userRequest('/balance-account')
}

export async function CostCentersData () {
  return adminRequest('/cost-centers')
}

export async function EmployeesData () {
  return adminRequest('/employees')
}

export async function ExpenseReportsData () {
  return userRequest('/expense-reports')
}

export async function AdminData () {
  let data = null
  if (IsAuthenticated() && IsSysAdmin()) {
    data = backendRequest('fortnox')
  }

  if (!data) {
    redirect('/login')
  }

  return data
}

export function IsAuthenticated () {
  const user = getUserInfoData()

  // console.log('IsAuthenticated:', user?.name)

  return user != null
}

export function IsEmployee () {
  const user = getUserInfoData()

  return user && user.roles && user.roles.includes('user')
}

export function IsAdmin () {
  const user = getUserInfoData()

  return user && user.roles && (user.roles.includes('admin') || user.roles.includes('sys-admin'))
}

export function IsSysAdmin () {
  const user = getUserInfoData()

  return user && user.roles && user.roles.includes('sys-admin')
}

export function Username () {
  const user = getUserInfoData()

  return user ? user.Name : ''
}

export function LastLogin () {
  const user = getUserInfoData()

  return user && 'lastlogin' in user ? new Date(user.lastlogin) : Date.now()
}
