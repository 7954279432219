import React from 'react'
import { useLocation } from 'react-router-dom'
import { EmployeeTable } from '../components/Employee.jsx'

export function Employee (props) {
  const location = useLocation()
  const employee = location.state?.data[0]
  const transactions = location.state?.data[1]

  return (
    <div>
      <div>{EmployeeTable(employee, transactions)}</div>
    </div>
  )
}
