import React from 'react'
import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert'

import Logo from '../images/sinix_white_dot.png'
import {
  IsAuthenticated,
  IsEmployee,
  IsAdmin,
  IsSysAdmin,
  GetAndClearMessageError
} from '../components/user.jsx'

const Navbar = () => {
  const auth = IsAuthenticated()
  const employee = IsEmployee()
  const admin = IsAdmin()
  const sysAdmin = IsSysAdmin()
  const msgError = GetAndClearMessageError()

  return (
    <div className='navbar'>
      <div className='container'>
        <div className='logo'>
          <Link to='/home'>
            <img src={Logo} alt='' />
          </Link>
        </div>
        <div className='navigation'>
          {auth && employee ? <Link className='link' to='/me'>Me</Link> : null}
          {auth && employee ? <Link className='link' to='/balance-account'>Balance Account</Link> : null}
          {auth && employee ? <Link className='link' to='/expense-reports'>Expense Reports</Link> : null}
          {admin ? <Link className='admin-link' to='/cost-centers'>Cost Centers</Link> : null}
          {admin ? <Link className='admin-link' to='/employees'>Employees</Link> : null}
          {sysAdmin ? <Link className='admin-link' to='/admin'>Admin</Link> : null}
          {auth ? <Link className='link' to='/logout'>Logout</Link> : <Link className='link' to='/login'>Login</Link>}
        </div>
      </div>
      {msgError ? <Alert severity='error'>{msgError}</Alert> : <></>}
    </div>
  )
}

export default Navbar
