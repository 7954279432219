import React, { useState } from 'react'
import {
  useLoaderData,
  redirect
} from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import Alert from '@mui/material/Alert'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { AdminData, CostCentersData, EmployeesData } from '../components/user.jsx'
import { config } from '../components/config.jsx'

function toHoursMinutes (totalSeconds) {
  const totalMinutes = Math.floor(totalSeconds / 60)

  const seconds = Math.floor(totalSeconds % 60)
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return `${hours} hours, ${minutes} minutes, ${seconds} seconds`
}

const backend = axios.create({
  withCredentials: true,
  baseURL: config.dusk_url + '/sunshine'
})

export function AdminLayout () {
  const [integrations, companyCostCenters, companyEmployees] = useLoaderData()

  const [hcResult, setHcResult] = React.useState([])

  const healthcheckOpen = () => {
    const results = []
    for (const company of Object.keys(companyEmployees)) {
      const result = { id: uuidv4(), company, ok: 0, nok: 0, log: [] }

      for (const employee of companyEmployees[company]) {
        const costCenter = companyCostCenters[company].find(cc => cc.code === employee.costcenter)
        const transactions = costCenter ? costCenter.transactions : null

        if (!transactions) {
          continue
        }

        for (let i = 0; i < transactions.length; i++) {
          const date = new Date(transactions[i].date)
          transactions[i].date = date
        }

        transactions.sort(function (a, b) { return a.date - b.date })

        let total = 0
        let ok = true
        for (let i = 0; i < transactions.length - 1; i++) {
          const t1 = transactions[i]
          const t2 = transactions[i + 1]

          total += t1.amount
          if (t1.date.getYear() !== t2.date.getYear()) {
            const year = 1900 + t1.date.getYear()
            if (Math.round(total * 100) / 100 !== 0.0) {
              result.log.push(employee.name + ' ' + year + ': failed, total at the end of the year: ' + total)
              ok = false
            }
          }
        }

        if (ok) {
          result.ok += 1
        } else {
          result.nok += 1
        }
      }
      results.push(result)
    }
    console.log(results)
    setHcResult(results)
  }

  const [syncResult, setSyncResult] = useState(0) /* 1=wait, -1=fail */
  const [syncInfo, setSyncInfo] = useState([])
  const sync = async (integration) => {
    setSyncResult(1)
    setSyncInfo('Sync started for ' + integration.name)
    await backend.post(integration.sync_url)
      .then(function (response) {
        if (response.status === 200) {
          setSyncResult(0)
          window.location.reload(false)
        } else {
          setSyncResult(-1)
          setSyncInfo('Sync failed for ' + integration.name)
        }
      })
      .catch(function () {
        setSyncResult(-1)
        setSyncInfo('Sync failed for ' + integration.name)
      })
  }

  const login = () => {
    window.location.href = config.dusk_url + '/fnx/login'
  }

  for (let i = 0; i < integrations.length; i++) {
    integrations[i].id = uuidv4()
  }

  return (
    <div>
      {syncResult === 1 ? <Alert severity='info'>{syncInfo}</Alert> : <></>}
      {syncResult === -1 ? <Alert severity='error'>{syncInfo}</Alert> : <></>}
      <h2>Admin</h2>
      <table border='solid'>
        <tbody>
          <tr><td className='title'>Company</td><td>Last sync</td><td>Number of syncs</td><td>Sync now</td></tr>
          {integrations.map(integration => (
            <tr key={integration.name}>
              <td>{integration.name}</td>
              <td align='right'>{integration.last_sync_seconds === -1 ? <p>not-synced</p> : toHoursMinutes(integration.last_sync_seconds)}</td>
              <td align='right'>{integration.count}</td>
              <td align='center'>{integration.last_sync_seconds !== -1 ? <Button onClick={async () => await sync(integration)}>⭮</Button> : <p />}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <Button onClick={() => login()}>Login and initiate sync</Button>
      <br />
      <Button onClick={healthcheckOpen}>Healthcheck transactions</Button>
      <Box sx={{ mx: 2 }}>
        {hcResult.map((row) => (
          <p key={row.id}>{row.company}: checked {row.ok}/{row.ok + row.nok} ok {row.log.length ? 'Info: ' + row.log.toString() : ''}</p>
        ))}
      </Box>
    </div>
  )
}

export const adminLoader = async () => {
  let adminData = null
  await AdminData()
    .then(function (data) {
      adminData = data
    })

  let costCenters = null
  await CostCentersData()
    .then(function (data) {
      costCenters = data
    })

  let employees = null
  await EmployeesData()
    .then(function (data) {
      employees = data
    })

  return adminData && costCenters && employees ? [adminData, costCenters, employees] : redirect('/login')
}
