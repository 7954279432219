import React from 'react'
import {
  useLoaderData,
  redirect
} from 'react-router-dom'

import { EmployeeTable } from '../components/Employee.jsx'
import { MeData, BalanceAccountData } from '../components/user.jsx'

export function Me () {
  const data = useLoaderData()

  const me = data[0]
  const balanceAccount = data[1]

  if (me == null || balanceAccount == null) {
    return
  }

  return (
    <>
      {EmployeeTable(me, balanceAccount)}
    </>
  )
}

export const meLoader = async () => {
  let me = null
  await MeData()
    .then(function (data) {
      me = data
    })

  let transactions = null
  await BalanceAccountData()
    .then(function (data) {
      transactions = data
    })

  return me && transactions ? [me, transactions] : redirect('/login')
}
