import React from 'react'
import {
  useLocation
} from 'react-router-dom'
import ToggleButton from '@mui/material/ToggleButton'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { green } from '@mui/material/colors'

import {
  TransactionsGraph,
  TransactionsTable
} from '../components/Transactions.jsx'
import { LastLogin } from '../components/user.jsx'

const theme = createTheme({
  palette: {
    primary: {
      main: green[500]
    }
  },
  root: {
    width: '0.5rem'
  }
})

export function CostCenter (props) {
  const [selected, setSelected] = React.useState(false)

  const location = useLocation()
  const costCenter = location.state?.data
  const lastLogin = LastLogin()

  return (
    <ThemeProvider theme={theme}>
      <ToggleButton
        value='check'
        selected={selected}
        color='primary'
        onChange={() => {
          setSelected(!selected)
        }}
      >
        Include account 7219 (R-lön)
      </ToggleButton>
      <h2>{costCenter.description}</h2>
      <h2>{costCenter.note}</h2>
      <div>{TransactionsGraph(costCenter.transactions, selected)}</div>
      <div>{TransactionsTable(costCenter.transactions, lastLogin, selected)}</div>
    </ThemeProvider>
  )
}
